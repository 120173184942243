@import "../../../styles/vars";
.form {
  text-align: center;
}
.form__input-box {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.form__input {
  outline: none;
  border: none;
  border-bottom: 1px solid $divider-color;
  padding-bottom: 8px;
  width: 100%;
  color: $main-color;
  background-color: transparent;
  text-align: center;
  transition: all 250ms ease-in-out;
  &::placeholder {
    color: $secondary-color;
    font-weight: 500;
    text-transform: uppercase;
  }
  &:focus, &:active {
    border-bottom-color: $main-color;
  }
  &.form__input--error {
    border-bottom-color: $error-color;
  }
  &.form__input--error::placeholder {
    color: $error-color;
  }
}
.input__requared {
  position: relative;
}
.form__error {
  color: $error-color;
  font-size: 10px;
  font-weight: 400;
  text-align: center;
}
.form__btn {
  margin: 24px auto 0;
  text-align: center;
  &.form__btn--hireUs {
    margin-top: 42px;
  }
}
.form__message {
  height: 243px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.input__cv {
  display: flex;
  flex-direction: column;
  gap: 8px;
  & span {
    font-size: 12px;
    font-weight: 400;
  }
}
.upload-container {
  position: relative;
  display: inline-block;
}

.file-input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.file-label {
  display: inline-flex;
  align-items: center;
  padding: 8px 12px;
  background-color: #383838;
  border: 2px solid $accent-color;
  border-radius: 30px;
  color: $accent-color;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
}

.file-label img {
  margin-left: 10px;
  width: 16px;
  height: 16px;
}

.file-label:hover {
  background-color: #505050;
}


@media screen and (min-width: 820px) {
  .form__input-box {
    gap: 12px
  }
  .form__btn {
    &.form__btn--hireUs {
      margin-top: 90px;
    }
  }
  .form__message {
    height: 310px;
    transform: translateY(-52px);
  }
}
@media screen and (min-width: 1440px) {
  .form__input-box {
    gap: 24px;
  }
  .form__input {
    padding-bottom: 16px;
  }
  .form__btn {
    margin-top: 48px;
    &.form__btn--hireUs {
      margin-top: 48px;
    }
  }
  .form__message {
    height: 420px;
    transform: translateY(-60px);
  }
  .form__error {
    font-size: 12px;
   }
  .input__cv {
    gap: 16px;
    & span {
      font-size: 16px;
    }
  }
  .file-label {
    padding: 8px 16px 8px 24px;
  }
  .file-label img {
    width: 24px;
    height: 24px;
  }
}
@media screen and (min-width: 1920px) {
  .form__input-box {
    gap: 24px;
  }
  .form__input {
    padding-bottom: 24px;
  }
  .form__message {
    height: 526px;
    transform: translateY(-80px);
  }
  .form__error {
    font-size: 16px;
  }
  .input__cv {
    flex-direction: row;
    gap: 24px;
    align-items: center;
  }
  .file-label {
    padding: 14px 24px 14px 32px;
    width: 200px;
    & img {
      width: 32px;
      height: 32px;
    }
  }
}